@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
html{
  font-size: 16px;
}

.one-half{
  width: calc(50% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}
.one-whole{
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}
.one-third{
  width: calc(33.3333% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}

.two-thirds{
  width: calc(66.6666% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}

.recruitmentprocedure-content--wrap {
  display: flex;
  flex-wrap: wrap;
}

.left-content ul {
  padding-left: 20px;
}

.left-content h2 {
  padding-top: 25px;
}

.left-content ul li {
  padding-left: 6px;
}

.Client-content, .candidate-content {
  padding-left: 25px;
}

.Client-content p, .candidate-content p {
  font-weight: 600;
  font-size: 15px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.chakra-container.recruitmentprocedure-content--wrap.css-lyimn6 {
  background: #fff;
  /* box-shadow: 0px 0px 7px #3333333b; */
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 25px;
}

.recruitment-wrapper {padding: 50px 0px;}

@media only screen and (min-width: 600px) and (max-width: 991px){
  .medium-one-half{
    width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
  .medium-one-whole{
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
  .medium-one-third{
    width: calc(33.3333% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
  .candidate-recruitment-wrapper {
    flex-direction: column-reverse;
}
}

@media only screen and (max-width: 600px){
  .candidate-recruitment-wrapper {
    flex-direction: column-reverse;
}
  .small-one-half{
    width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
  .small-one-whole{
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
  .small-one-third{
    width: calc(33.3333% - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
}


div#root {
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Montserrat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Open Sans';
  src: url('https://fonts.cdn.com/OpenSans-Regular.ttf');
}
body.loaded {
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Montserrat',
    monospace ,;
}
.homepage-slider-wrap{
  position: relative;
}
.homepage-slider-wrap:before {
  content:"";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, .3);
}
button.chakra-button.right-arrow:hover, button.chakra-button.left-arrow:hover {
  background: transparent;
}
button.chakra-button.right-arrow svg, button.chakra-button.left-arrow svg {
  fill: #fff;
  border: 3px solid #fff;
  border-radius: 50px
}
.header-wrap.css-1szm2p0 {
  position: sticky;
  margin-bottom: 0;
  top: 0;
  position: -webkit-sticky;
}
.chakra-stack.image-item-wrapper.row.css-1485kjp {
  -webkit-flex-direction: unset;
  -ms-flex-direction: unset;
  flex-direction: unset;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
label.chakra-form__label{
  font-family: 'Open Sans';
  FONT-WEIGHT: 500;
    font-size: 13px;
}
input, textarea, select, button#picky__button__button{
    background: transparent !important;
    border: 1px solid gray !important;
    border-radius: 10px !important;
    min-height: 40px;
    font-family: 'Open Sans' !important;
    font-weight: 400;
}
.chakra-input__left-element {
    min-height: 40px;
}
button.chakra-button.css-1q9huvs {
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.12);
    border-radius: 50px;
    min-height: 40px;
}
button.chakra-button.candidate-submit-wrap,
button.client-submit-wrapper {
  background: #46aceb;
  border-radius: 50px;
  min-height: 40px;
  min-width: 100px;
  line-height: 22px;
}
button.chakra-button.candidate-cancel-wrap,
button.client-cancel-wrapper {
  border:2px solid #333;
  border-radius: 50px;
  min-height: 40px;
  min-width: 100px;
  line-height: 22px;
}
.candidate-wrapper {
  padding: 40px 0;
}
.client-wrapper{
  padding: 40px;
}
h2.chakra-heading.candidate-heading {
  padding: 0 20px;
}
.upload--wrapper * div {
  background: transparent;
  border-color: gray;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.candidate-wrapper:before,.client-wrapper:before{
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 14.125rem;
  width: 14.125rem;
  background: #e2f1ff;
  top: 10%;
  right: 0;
  -webkit-transform: translate(50%,50%);
  -ms-transform: translate(50%,50%);
  transform: translate(50%,50%);
}
.recruitment-wrapper .bgservice-wrap {
  width:16.6875rem;
  height:36.5rem; 
  position: absolute;
  top: 0.3rem;
  right: 0;
}
.chakra-stack.recruitmentprocedure--wrap,
.aboutrhythm--wrap, .messagefromfounder-wrap, .selectionprocedure-wrapper {
  padding: 40px 20px;
}
.recruitmentprocedure--wrap h2.chakra-heading,
.chakra-stack.selectionprocedure-wrapper h2.chakra-heading {
  margin-bottom: 10px;
}
.parallax-about-us {
  margin-top: 0;
}
.license--wrapper {
  padding: 0 30px 30px;
}
.chakra-menu__menu-list button.chakra-menu__menuitem,
.dropdown---menu-wrap .chakra-menu__menuitem  {
     font-size: 13px;
     FONT-WEIGHT: 400;
    padding: 8px 10px;
}
.chakra-menu__menu-list button.chakra-menu__menuitem:hover,
.dropdown---menu-wrap .chakra-menu__menuitem:hover {
 
   color: #fff;
}
.testimonial-content-wrapper .css-0 {
  width: calc(33.3333% - 20px); 
  margin: 0 10px 20px;
}
.testimonial-content-wrapper .chakra-stack p.chakra-text.css-3c0zu9 {
  min-height: 91px;
}
.mobile-nav-wrap button.chakra-button.HamburgerIcon svg {
  width: 24px;
  height: 24px;
  fill: #000;
}
.section-main-smokewhite.homepage-blog-wrap img.chakra-image.css-1vbwyhx {
  height: 250px;
  object-fit: cover;
  width: 100%;
}
.chakra-stack.blog-heading .chakra-card__body {
  padding: 0px 15px;
}
.chakra-stack.blog-heading{
  /* min-height: 270px; */
}
h2.chakra-heading.css-hp1zg0 {
  margin-bottom: 20px;
}
.chakra-container.css-1fgkg8t .css-1nmq59o {
  margin: 0;
  padding: 20px 20px 40px 0;
}
.chakra-stack.footer-inner p.chakra-text {
  color: #fff;
}
div.chakra-container.css-1fgkg8t .css-1r21lrd {
  margin: 0;
}
.chakra-container.lets-talk-wrap form{
  margin-top: 20px;
  margin-bottom: 20px;
}
.chakra-stack.license--wrapper h2.chakra-heading {
  margin-bottom: 30px;
}
div.chakra-container.css-1fgkg8t .css-1r21lrd {
  align-items: flex-start;
  justify-content: flex-start;
}
.chakra-modal__content-container {
  width: 100% !important;
  height: 100% !important;
  align-items: center !important;
}
.blog-content--wrap img.chakra-image {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
.main-blog--wrapper {
  align-items: flex-start;
}
.chakra-stack.teams-content-wrapper {
  margin: 0;
  padding: 15px 0 20px;
}
section.chakra-modal__content {
  width: calc(100% - 20px);
  margin: 0 auto;
  max-width: 600px;
}
.ourteam-content .chakra-card {
  margin-top: 0;
  margin-bottom: 20px;
}
.blog-content--wrap h2.chakra-heading {
  min-height: 90px;
}
.blog-content--wrap .chakra-card__body {
  padding-top: 0;
  padding-bottom: 0;
}
.contact-right-wrapper button.chakra-button {
  FONT-WEIGHT: 600;
  font-family: 'Open Sans';
  background: transparent;
    cursor: pointer;
}
.contact-wrapper button.chakra-button, .chakra-container.lets-talk-wrap button.chakra-button {
  height: 40px;
  border-radius: 50px;
}

.footer--wrapperr p{
  text-align: left;
}

.license--wrapper .license-item {
  margin: 0 10px;
}
.chakra-menu__menu-button span {
  display: flex;
  align-items: center;
}
.chakra-menu__menu-button span svg{
  fill: #84868b;
}
h2.chakra-heading.certificate-heading{
  padding: 0;
}
button.chakra-button.client-form-btn{
  border: 2px solid #333;
  background: transparent;
}
.chakra-menu__menu-list button.chakra-menu__menuitem:last-child, 
.dropdown---menu-wrap .chakra-menu__menuitem:last-child {
  border-radius: 0 0 4px 4px;
}
.chakra-menu__menu-list button.chakra-menu__menuitem:first-child, 
.dropdown---menu-wrap .chakra-menu__menuitem:first-child {
  border-radius: 4px 4px 0 0;
}

/* recruitment */
.client-content img {
  max-width: 100px;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.candidates-content img {
  max-width: 80px;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  object-fit: cover;
}
.client-content, .candidates-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.client--wrapper{
  max-width: 320px;
    margin:0 auto;
}
.candidates--wrapper{
  max-width: 320px;
  margin: 0 auto;
}
.client-content p, .candidates-content p {
  max-width: 111px;
  word-break: break-word;
  text-transform: uppercase;
  font-size: 12px;
  FONT-WEIGHT: 600;
  font-family: 'Open Sans';
}


.area{
  background: #F7FAFC;  
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
  width: 100%;
  height:100%; 
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #46aceb;
  animation: animate 25s linear infinite;
  top: 50%;
  
}

.circles li:nth-child(1){
  left: 5%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  background: #f56565;
}


.circles li:nth-child(2){
  background: #333;
  left: 10%;
  width: 30px;
  height: 30px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  background: gray;
  left: 90%;
  width: 50px;
  height: 50px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 14%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
  background: #5cc84e;
}

.circles li:nth-child(5){
  left: 91%;
  width: 40px;
  height: 40px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 98%;
  width: 35px;
  height: 35px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 7%;
  width: 80px;
  height: 80px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 95%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 6%;
  width: 45px;
  height: 45px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 90px;
  height: 90px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}



.gradient-background {
  height: 100%;
  background: linear-gradient(62deg, #46aceba8, #f5656594, #0385cbaf, #e2b78e9a);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  position: absolute;
  width: 100%;
}
@-webkit-keyframes gradient{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
     background-position: 0% 50%;
  }
}
@keyframes gradient{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
     background-position: 0% 50%;
  }
}
@media only screen and (max-width:600px) {
 
  div.blog-content--wrap h2.chakra-heading{
    min-height: auto;
    margin-bottom: 10px;
  }
  div.testimonial-content-wrapper .chakra-stack p.chakra-text.css-3c0zu9 {
    min-height: auto;
  }
  button.chakra-button.right-arrow svg, button.chakra-button.left-arrow svg{
    display: none;
  }
  .chakra-container.testclass.css-ssek7p {
    padding: 0;
  }
  div.chakra-container.css-1fgkg8t .css-1nmq59o {
    margin: 0;
    padding: 10px 12px 20px;
  }
  div.chakra-stack.blog-heading.css-11chzwm, div.chakra-card.blog-content--wrap .blog-desc-wrap{
    min-height: inherit;
  }
  .chakra-container.lets-talk-wrap {
    padding: 40px 20px 20px;
  }
  .chakra-stack.license--wrapper {
    padding: 0 10px 30px;
  }
  .license--wrapper {
    padding: 0 20px;
  }
  .client-wrapper.css-zt3snn{
    padding: 40px 20px;
  }
  .candidate-wrapper .chakra-stack.css-vheve5 {
    padding: 0 10px;
  }
  .chakra-stack.css-1om6179 {
    width: calc(93% - 20px);
  }
  .chakra-stack.recruitmentprocedure--wrap,
    .aboutrhythm--wrap, .messagefromfounder-wrap, .selectionprocedure-wrapper {
      padding: 30px 10px !important;
  }
  .selectionprocedure-wrapper p.chakra-text,
  .chakra-stack.aboutrhythm--wrap p.chakra-text,
  .messagefromfounder-wrap p.chakra-text, .chakra-stack.recruitmentprocedure--wrap p.chakra-text{
    padding: 0;
  }
  div.testimonial-content-wrapper .css-0 {
    width: 100%;
    margin-bottom: 20px;
  }
  div.about-us---wrapper .css-1887jb7, div.chakra-container .chakra-card.blog-content--wrap {
    width: calc(100% - 20px);
    margin: 0 10px 20px;
  }
  div.license--wrapper .license-item {
    width: calc(100% - 20px) !important;
}
}

@media only screen and (min-width:601px) {
.home-blog--wrapper h2.chakra-heading {
  min-height: 65px;
  margin-bottom: 8px;
}
.home-blog--wrapper .description-wrap {
  min-height: 63px;
}
.footer--wrapperr .text-center {
  text-align: center;
}
}
@media only screen and (max-width:800px) {
  .testimonial-content-wrapper .css-0 {
    width: calc(50% - 20px);
    margin: 0 10px 20px;
  }
    button.chakra-button.css-otsp76:last-child {
      padding: 34px 0px;
    }
  div.license--wrapper .license-item {
    width: calc(50% - 20px);
  }
  .chakra-stack.recruitmentprocedure--wrap,
  .aboutrhythm--wrap, .messagefromfounder-wrap, .selectionprocedure-wrapper {
    padding: 40px 20px;
}
  .chakra-container.testclass.css-rya1az {
    width: calc(100% - 20px);
    max-width: 600px;
    margin: 0 auto;
    max-width: 600px;
    padding: 0 30px;
}
  button.chakra-button.left-arrow {
    left: 10px;
    top:50%;
  }
  button.chakra-button.right-arrow{
    right: 10px;
    top:50%;
  }
  p.chakra-text.css-1jvycn6 {
    text-align: left;
    padding: 0;
  }
}
@media only screen and (max-width:991px) {
  div.header-wrap a.chakra-link, div.header-wrap .chakra-menu__menu-button {
    font-size: 16px;
  }
  .submenu-item > * {
    position: unset !important;
    transform: unset !important;
  }
  .submenu-item {
    display: none;
  }
  .submenu-item.menu_active{
    display: block;
  }
  .submenu-item.menu_active .chakra-menu__menu-list {
    opacity: 1 !important;
    visibility: visible !important;
    transform: unset !important;
    border: none;
    transition: all 0.5s ease-in-out;
    outline: none;
    box-shadow: none;
    padding: 0;
  }
  div.chakra-menu__menu-list button.chakra-menu__menuitem, div.dropdown---menu-wrap .chakra-menu__menuitem {
    font-size: 15px;
  }
  .footer--wrapperr button.chakra-button.css-otsp76:last-child {
      padding: 34px 0px;
    }
  .hambruger-wrapper {
    height: 100vh;
    overflow-y: auto;
    padding: 0 24px;
}
  .license--wrapper .license-item {
    width: calc(33.3333% - 20px);
}
  .dropdown---menu-wrap, .chakra-menu__menu-list  {
    margin: 0 10px;
}
  .mobile-nav-wrap.css-70qvj9 {
    width: 100%;
    justify-content: space-between;
  }
  .about-us---wrapper .css-1887jb7,
  .chakra-container .chakra-card.blog-content--wrap {
    width: calc(50% - 20px);
    margin: 0 10px 20px;
}
}
@media only screen and (max-width:1100px) {
  .footer--wrapperr button.chakra-button.css-otsp76:last-child {
      padding: 34px 0px;
    }
  .header-wrap a.chakra-link, .header-wrap .chakra-menu__menu-button {
    font-size: 14px;
  }
}
@media only screen and (min-width:992px) {
  .footer--wrapperr button.chakra-button.css-otsp76:last-child {
      padding: 34px 0px;
    }
  .article-content-wrapper > * {
    width: 95%;
}
  .dropdown---menu-wrap, .chakra-menu__menu-list  {
        padding: 0 !important;
       margin-top: -4px;
    }
    .license--wrapper .license-item {
      width: calc(25% - 20px);
      margin: 0 10px;
  }
  .about-us---wrapper .css-1887jb7 {
    width: calc(33.333% - 20px);
    margin: 0 10px 20px;
  } 
  .chakra-container .chakra-card.blog-content--wrap {
    max-width: max-content;
    width: calc(33.3333% - 20px);
  }
}

.lets-talk-wrap, .candidate-wrapper, .client-wrapper, .recruitment-wrapper{
  position: relative;
} 
.lets-talk-wrap .shape, .candidate-wrapper .shape,
.client-wrapper .shape, .recruitment-wrapper .shape {
  position: absolute;
  z-index: 0;
 }
.lets-talk-wrap .header-shape-one,
.candidate-wrapper .header-shape-one,
.client-wrapper .header-shape-one, .recruitment-wrapper .header-shape-one{
  top: 0;
  left: 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .lets-talk-wrap .header-shape-one, .candidate-wrapper .header-shape-one,
    .client-wrapper .header-shape-one, .recruitment-wrapper .header-shape-one {
      width: 230px; } }
  @media (max-width: 767px) {
    .lets-talk-wrap .header-shape-one, .candidate-wrapper .header-shape-one,
    .client-wrapper .header-shape-one, .recruitment-wrapper .header-shape-one {
      width: 180px; } }
.lets-talk-wrap .header-shape-two, .candidate-wrapper .header-shape-two,
.client-wrapper .header-shape-two, .recruitment-wrapper .header-shape-two {
  top: 15px;
  right: 31%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .lets-talk-wrap .header-shape-two, .candidate-wrapper .header-shape-two,
    .client-wrapper .header-shape-two, .recruitment-wrapper .header-shape-two {
      left: 40%; } }
  @media (max-width: 767px) {
    .lets-talk-wrap .header-shape-two, .candidate-wrapper .header-shape-two,
    .client-wrapper .header-shape-two, .recruitment-wrapper .header-shape-two {
      left: 60%;
      width: 60px; } }
.lets-talk-wrap .header-shape-three, .candidate-wrapper .header-shape-three,
.client-wrapper .header-shape-three, .recruitment-wrapper .header-shape-three {
  left: 70px;
  bottom: 130px;
}
  @media (max-width: 767px) {
    .lets-talk-wrap .header-shape-three, .candidate-wrapper .header-shape-three,
    .client-wrapper .header-shape-three, .recruitment-wrapper .header-shape-three{
      width: 90px;
      bottom: 200px; } }
.lets-talk-wrap .header-shape-four, .candidate-wrapper .header-shape-four,
.client-wrapper .header-shape-four, .recruitment-wrapper .header-shape-four {
  right: 0px;
  bottom: 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .lets-talk-wrap .header-shape-four, .candidate-wrapper .header-shape-four,
    .client-wrapper .header-shape-four, .recruitment-wrapper .header-shape-four {
      width: 260px; } }
  @media (max-width: 767px) {
    .lets-talk-wrap .header-shape-four, .candidate-wrapper .header-shape-four,
    .client-wrapper .header-shape-four, .recruitment-wrapper .header-shape-four {
      bottom: -60px;
      width: 210px; } }
      .animation-one {
        -webkit-animation: rotate 15s linear infinite;
        -moz-animation: rotate 15s linear infinite;
        -o-animation: rotate 15s linear infinite;
        animation: rotate 15s linear infinite; }
      
      @keyframes rotate {
        0% {
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg); }
        100% {
          -webkit-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg); } }



/* for transition */
.testimonial-reveal {
  position: relative;
  opacity: 0;
}

.testimonial-reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right {
  animation: fade-right 1s ease-in;
}
@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
