@import url('https://fonts.cdnfonts.com/css/montserrat');
.logoDiv{
    display: flex;
    width: 100px;
    height: 100px;
    padding: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    background-image: linear-gradient(349deg, #289e00, #018601 52%, #095000);
}
.logoImage{
  display: inline-block;
  max-width: 100%;
}

.sideBarLogo{
  border-radius: 50%;
  background-image: linear-gradient(349deg, #289e00, #018601 52%, #095000);
}

#fill-4{
  fill: #018601;
}
.css-abtqdh {
  padding-left: 0px;
}
.chakra-stack.css-c0t1fd button {
  padding-left: 0;
}
.css-a8rk6k{
      background-position-y: 78px;
      background-position: center;
      margin-top: 34px;
}
.css-1szm2p0{
  top:0;
}
nav.chakra-stack button{
  text-align: left;
  padding-left: 10px;
}
.picky__dropdown div.option {
  display: flex;
  align-items: center;
  justify-content: start;
}
.picky__dropdown div.option input[type='checkbox'] {
  margin-right: 10px;
}